.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
  height: calc(100vh - 69px);
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}
.wrap {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #dedede;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatMain {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  overflow: auto;
  padding: 8px;
  position: relative;
}
.leftBubble {
  background-color: #e8e8e8;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  width: calc(50% - 2px);
  padding: 8px;
  margin: 2px ;
}
.rigthBubble {
  width: calc(50% - 2px);
  margin: 2px 0 2px auto ;
  background-color: #bfeeee;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  padding: 8px;
}
.inputWrap {
  width: 100%;
  display: flex;
  padding: 16px 8px;

  input {
    height: 50px;
    padding: 4px 8px;
    width: 100%;
    font-size: 18px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #dedede;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.3);  
    margin-right: 8px;  
  }
}