.main {
  height: calc(100vh - 69px);
  display: flex;
}
.wrap {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: url('../../images/bgBW.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.textWrap {
  margin: auto;
  max-width: 750px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px 16px 32px 16px;
  box-shadow: 0 2px 4px 1px gray;
  h2 {
    font-size: 26px;
  }
  p  {
    font-size: 18px;
  }
  button {
    padding: 20px 80px;
    font-size: 24px;
    margin-top: 24px;
  }
  .buttonExtra {
    margin-right: 16px;
  }
}