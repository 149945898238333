.main {
  height: calc(100vh - 69px);
  * {
    box-sizing: border-box;
  }
}
.wrap {
  max-width: 1440px;
  margin: auto;  
  height: 100%;
  display: flex;
  flex-direction: column;
}
.docListWrap {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.docWrap {
  flex: 1;
  height: 60px;
  min-height: 60px;
  border-radius: 4px;
  border: 1px solid #000000;
  margin: 4px;
}
.doc {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px;
  text-align: left;
}
.ready {
  color: #023dff
}
.processing {
  color: #ed2f2f
}
.uploadDiv {
  display: flex;
  padding: 16px 0 32px 0;
  align-items: center;
  justify-content: center;
  input[type="file"] {
    display: none;
  }
  .uploadButton {
    cursor: pointer;
    width: 300px;
    height: 140px;
    background: #dedede;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    outline: none;
    border: none;
    height: 50px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #ffa436;
    color: #ffffff;
    font-size: 20px;
    margin: auto 16px;
    cursor: pointer;
  }
}
.labelWrap {
  position: relative;
}
.label {
  position: absolute;
  bottom: -16px;
  left: 0;
  font-size: 12px;
}